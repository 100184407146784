import { AmbientLight, DirectionalLight, Event, Object3D, SpotLight, Vector3 } from 'three';

const LIGHTS_POWER = 0.5;

export function drawLights(): Object3D<Event> {
  const lightGroup = new Object3D();

  const aLight = new AmbientLight(0xffffff, 0.7);

  const dLight1 = new DirectionalLight(0xffffff, 1.0 * LIGHTS_POWER);
  dLight1.position.set(-3, 2, -2);
  dLight1.lookAt(new Vector3(0, 0, 0));

  const dLight2 = new DirectionalLight(0xffffff, 0.1 * LIGHTS_POWER);
  dLight2.position.set(2, 3, -2);

  const dLight3 = new SpotLight(0xefefff, 0.7 * LIGHTS_POWER);
  dLight3.position.set(-3, 2, -1);

  lightGroup.add(aLight).add(dLight1).add(dLight2).add(dLight3);

  return lightGroup;
}
