export function getImageData(image: ImageData): ImageData | undefined {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  const context = canvas.getContext('2d');
  if (context) {
    context.drawImage(image as unknown as CanvasImageSource, 0, 0);
    return context.getImageData(0, 0, image.width, image.height);
  }
}

export function polar2canvas({ phi, theta }: { phi: number; theta: number }): {
  y: number;
  x: number;
} {
  return {
    y: phi / Math.PI,
    x: (theta + 0.5 * Math.PI) / (2 * Math.PI),
  };
}

export function getPixel(
  imagedata: ImageData,
  x: number,
  y: number,
): {
  r: number;
  g: number;
  b: number;
  a: number;
} {
  const position = (Math.round(x) + imagedata.width * Math.round(y)) * 4;
  const { data } = imagedata;
  return {
    r: data[position],
    g: data[position + 1],
    b: data[position + 2],
    a: data[position + 3],
  };
}
