import { PerspectiveCamera, Vector3 } from 'three';

export default function setupCamera(): PerspectiveCamera {
  const camera = new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);

  camera.position.set(-2, 2, 1);
  camera.up = new Vector3(1, 0, -0.9);
  camera.lookAt(new Vector3(0, 0, 0));

  return camera;
}
