import React, { FC } from 'react';
import styled from '@emotion/styled';

import FooterMenu from 'app/components/layout/FooterMenu';
import FooterDetails from '../common/FooterDetails';

const LandingFooter: FC = ({ ...rest }) => {
  return (
    <Wrapper data-testid='LandingFooter-Wrapper' {...rest}>
      <FooterTextWrapper>
        <FooterMenu />
        <DetailsWrapper>
          <FooterDetails />
        </DetailsWrapper>
      </FooterTextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  opacity: 0.5;
  a {
    color: #ffffff;
  }
`;

const FooterTextWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const DetailsWrapper = styled.div`
  text-align: center;
  color: #ffffff;
  margin: 1em 0;
`;

export default LandingFooter;
